<template>
  <div>
    <form-wizard
      color="#7367F0"
      :title="null"
      :subtitle="null"
      shape="square"
      finish-button-text="Submit"
      back-button-text="Previous"
      class="mb-3"
      @on-complete="formComplete"
    >

      <!-- Rate Confirmation Document -->
      <tab-content
        title="Rate Confirmation Document"
        :before-change="rateConUpload"
      >
        <validation-observer
          ref="rateconRules"
          tag="form"
        >
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h5 class="mb-0">
                Rate Confirmation Document
              </h5>
              <small class="text-muted">
                To Create ***Booking*** you must upload rate confirmation document first
              </small>
            </b-col>

            <b-col md="12">
              <label
                for="rate_conformation"
                class="d-flex justify-content-center"
              >
                <b-img
                  :src="uploadThumbnail"
                  style="width: 300px;"
                />
              </label>
              <b-form-group
                label="Rate Confirmation"
                label-for="rate_conformation"
                class="d-none"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Rate Confirmation"
                  rules="required"
                >
                  <b-form-file
                    id="rate_conformation"
                    v-model="load.rate_conformation"
                    :state="errors.length > 0 ? false:null"
                    @change="fileupload"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

      <!-- load detail -->
      <tab-content
        title="Load Detail"
        :before-change="loadDetail"
      >
        <b-row>
          <b-col
            cols="12"
            class="mb-2"
          >
            <h5 class="mb-0">
              Load Details
            </h5>
            <small class="text-muted">
              You can multiple Pickup and Delivery by click "Pickup" or "Delivery" buttons. And You must fill all field to move next step.
            </small>
          </b-col>

          <b-col
            md="12"
            class="mb-1 d-flex align-items-center"
          >
            <b-button
              variant="primary"
              class="mr-1"
              @click="viewRateCon"
            >
              View Rate Confirmation Document
            </b-button>

            <b-form-file
              v-if="$route.name === 'edit-load'"
              v-model="load.revived_rate_conformation"
              class="w-50"
              placeholder="Revice Rate Confirmation"
            />
          </b-col>
        </b-row>

        <validation-observer
          ref="loadDetail"
          tag="form"
        >
          <b-row>

            <!-- Load ID -->
            <b-col md="4">
              <b-form-group
                label="Load ID"
                label-for="mc-loadID"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Load ID"
                  rules="required"
                >
                  <b-form-input
                    id="mc-loadID"
                    v-model="load.loadID"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Load ID"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- company -->
            <b-col md="4">
              <b-form-group
                label="Company"
                label-for="mc-company"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Company"
                  rules="required"
                >
                  <v-select
                    id="mc-company"
                    v-model="load.load_company"
                    :options="companies"
                    label="name"
                    :reduce="company => company.id"
                    placeholder="Company"
                    class="w-100"
                  >
                    <template
                      #option="{ id, name }"
                      :index="id"
                    >
                      {{ name }}
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- dispatcher -->
            <b-col md="4">
              <b-form-group
                label="Dispatcher"
                label-for="mc-dispatcher"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Dispatcher"
                  rules="required"
                >
                  <v-select
                    id="mc-dispatcher"
                    v-model="load.dispatcher"
                    :options="dispatchers"
                    label="name"
                    :reduce="dispatcher => dispatcher.id"
                    placeholder="Dispatcher"
                    class="w-100"
                  >
                    <template
                      #option="{ id, name }"
                      :index="id"
                    >
                      {{ name }}
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- equipment -->
            <b-col md="4">
              <b-form-group
                label="Equipment"
                label-for="mc-equipment"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Equipment"
                  rules="required"
                >
                  <v-select
                    id="mc-equipment"
                    v-model="load.equipment"
                    :options="equipments"
                    label="name"
                    :reduce="equipment => equipment.id"
                    placeholder="Equipment"
                    class="w-100"
                  >
                    <template
                      #option="{ id, name }"
                      :index="id"
                    >
                      {{ name }}
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- customer -->
            <b-col md="4">
              <b-form-group
                label="Customer"
                label-for="mc-customer"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Customer"
                  rules="required"
                >
                  <v-select
                    id="mc-customer"
                    v-model="load.customer"
                    :options="customers"
                    label="name"
                    :reduce="customer => customer.id"
                    placeholder="Customer"
                    class="w-100"
                    @input="selectVendor"
                  >
                    <template
                      #option="{ id, name }"
                      :index="id"
                    >
                      {{ name }}
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- customer_repName -->
            <b-col md="4">
              <b-form-group
                label="Customer RepName"
                label-for="mc-customer_rep"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Customer RepName"
                  rules="required"
                >
                  <v-select
                    id="mc-customer_rep"
                    v-model="load.customer_rep"
                    :options="customReps"
                    label="full_name"
                    :reduce="customer => customer.id"
                    placeholder="Customer RepName"
                    class="w-100"
                  >
                    <template
                      #option="{ id, full_name }"
                      :index="id"
                    >
                      {{ full_name }}
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- MC# -->
            <b-col md="4">
              <b-form-group
                label="MC#"
                label-for="mc-MC#"
              >
                <b-form-input
                  id="mc-MC#"
                  v-model="load.customer_mc"
                  placeholder="MC#"
                />
              </b-form-group>
            </b-col>

            <!-- Dot# -->
            <b-col md="4">
              <b-form-group
                label="Dot#"
                label-for="mc-Dot#"
              >
                <b-form-input
                  id="mc-Dot#"
                  v-model="load.customer_dot"
                  placeholder="Dot#"
                />
              </b-form-group>
            </b-col>

            <!-- Rate Confirmation Amount -->
            <b-col md="4">
              <b-form-group
                label="Rate Confirmation Amount"
                label-for="mc-rate_confirmation_amount"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Rate Confirmation Amount"
                  rules="required"
                >
                  <b-form-input
                    id="mc-rate_confirmation_amount"
                    v-model="load.rate_confirmation_amount"
                    type="number"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Rate Confirmation Amount"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <hr class="w-100">

            <b-col md="12">
              <b-row
                v-for="(operation, index) in load.operations"
                :key="index"
              >
                <b-col
                  md="12"
                  class="d-flex justify-content-between"
                >
                  <h4 class="text-danger text-decoration-underline bold">
                    # {{ `${index + 1} : ${operation.type}` }}
                  </h4>
                  <feather-icon
                    icon="Trash2Icon"
                    size="18"
                    class="text-danger"
                    style="cursor: pointer"
                    @click="removeItem(index)"
                  />
                </b-col>

                <!-- Address -->
                <b-col md="3">
                  <b-form-group
                    label="Address"
                    :label-for="`mc-operation_location${index}`"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Address"
                      rules="required"
                    >
                      <b-form-input
                        :id="`mc-operation_location${index}`"
                        v-model="operation.location"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Address"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- City -->
                <b-col md="3">
                  <b-form-group
                    label="City"
                    :label-for="`mc-operation_city${index}`"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="City"
                      rules="required"
                    >
                      <b-form-input
                        :id="`mc-operation_city${index}`"
                        v-model="operation.city"
                        :state="errors.length > 0 ? false:null"
                        placeholder="City"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- State -->
                <b-col md="3">
                  <b-form-group
                    label="State"
                    :label-for="`mc-operation_state${index}`"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="State"
                      rules="required"
                    >
                      <v-select
                        :id="`mc-operation_state${index}`"
                        v-model="operation.state"
                        :options="states"
                        label="abbreviated_state"
                        :reduce="state => state.abbreviated_state"
                        placeholder="State"
                        class="w-100"
                      >
                        <template
                          #option="{ abbreviated_state }"
                          :index="abbreviated_state"
                        >
                          {{ abbreviated_state }}
                        </template>
                      </v-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Zip -->
                <b-col md="3">
                  <b-form-group
                    label="Zip"
                    :label-for="`mc-operation_zip${index}`"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Zip"
                      rules="required"
                    >
                      <b-form-input
                        :id="`mc-operation_zip${index}`"
                        v-model="operation.zip"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Zip"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Start Date -->
                <b-col md="3">
                  <b-form-group
                    label="Start Date"
                    :label-for="`mc-operation_startDate${index}`"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Start Date"
                      rules="required"
                    >
                      <b-form-datepicker
                        :id="`mc-operation_startDate${index}`"
                        v-model="operation.startDate"
                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                        show-decade-nav
                        locale="en-US"
                        aria-controls="example-input"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Start Time -->
                <b-col md="3">
                  <b-form-group
                    label="Start Time"
                    :label-for="`mc-operation_startTime${index}`"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Start Time"
                      rules="required"
                    >
                      <b-form-timepicker
                        :id="`mc-operation_startTime${index}`"
                        v-model="operation.startTime"
                        placeholder="Choose a time"
                        local="en"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- End Date -->
                <b-col md="3">
                  <b-form-group
                    label="End Date"
                    :label-for="`mc-operation_endDate${index}`"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="End Date"
                      rules="required"
                    >
                      <b-form-datepicker
                        :id="`mc-operation_endDate${index}`"
                        v-model="operation.endDate"
                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                        show-decade-nav
                        locale="en-US"
                        aria-controls="example-input"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- End Time -->
                <b-col md="3">
                  <b-form-group
                    label="End Time"
                    :label-for="`mc-operation_endTime${index}`"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="End Time"
                      rules="required"
                    >
                      <b-form-timepicker
                        :id="`mc-operation_endTime${index}`"
                        v-model="operation.endTime"
                        placeholder="Choose a time"
                        local="en"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- FCFS -->
                <b-col md="3">
                  <b-form-group
                    label="FCFS"
                    :label-for="`mc-operation_fcfs${index}`"
                  >
                    <b-form-checkbox
                      :id="`mc-operation_fcfs${index}`"
                      v-model="operation.fcfs"
                      checked="true"
                      class="custom-control-primary"
                      name="check-button"
                      switch
                    >
                      <span class="switch-icon-left">
                        <feather-icon icon="CheckIcon" />
                      </span>
                      <span class="switch-icon-right">
                        <feather-icon icon="XIcon" />
                      </span>
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>

                <!-- Appointment -->
                <b-col md="3">
                  <b-form-group
                    label="Appointment"
                    :label-for="`mc-operation_appt${index}`"
                  >
                    <b-form-checkbox
                      :id="`mc-operation_appt${index}`"
                      v-model="operation.appt"
                      checked="true"
                      class="custom-control-primary"
                      name="check-button"
                      switch
                      @input="changeAppt(index, operation.appt)"
                    >
                      <span class="switch-icon-left">
                        <feather-icon icon="CheckIcon" />
                      </span>
                      <span class="switch-icon-right">
                        <feather-icon icon="XIcon" />
                      </span>
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>

                <hr class="w-100">

                <!-- Reference -->
                <b-col md="3">
                  <b-form-group
                    label="Reference"
                    :label-for="`mc-operation_reference${index}`"
                  >
                    <b-form-input
                      :id="`mc-operation_reference${index}`"
                      v-model="operation.reference"
                      placeholder="Reference"
                    />
                  </b-form-group>
                </b-col>

                <!-- Number -->
                <b-col md="3">
                  <b-form-group
                    :label="`${operation.type === 'pickup' ? 'Pickup Number' : 'Delivery Number'}`"
                    :label-for="`mc-operation_Number${index}`"
                  >
                    <b-form-input
                      :id="`mc-operation_Number${index}`"
                      v-model="operation.Number"
                      placeholder="Number"
                    />
                  </b-form-group>
                </b-col>

                <!-- Special Notes -->
                <b-col md="3">
                  <b-form-group
                    label="Special Notes"
                    :label-for="`mc-operation_specialNote${index}`"
                  >
                    <b-form-input
                      :id="`mc-operation_specialNote${index}`"
                      v-model="operation.specialNote"
                      placeholder="Special Notes"
                    />
                  </b-form-group>
                </b-col>

                <!-- SEAL -->
                <b-col md="3">
                  <b-form-group
                    label="SEAL"
                    :label-for="`mc-operation_seal${index}`"
                  >
                    <b-form-input
                      :id="`mc-operation_seal${index}`"
                      v-model="operation.seal"
                      placeholder="SEAL"
                    />
                  </b-form-group>
                </b-col>

                <hr class="w-100">

                <!-- Commodity -->
                <b-col md="3">
                  <b-form-group
                    label="Commodity"
                    :label-for="`mc-commodity${index}`"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Commodity"
                      rules="required"
                    >
                      <b-form-input
                        :id="`mc-commodity${index}`"
                        v-model="operation.commodity"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Commodity"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Weight -->
                <b-col md="3">
                  <b-form-group
                    label="Weight"
                    :label-for="`mc-operation_weight${index}`"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Weight"
                      rules="required"
                    >
                      <b-form-input
                        :id="`mc-operation_weight${index}`"
                        v-model="operation.weight"
                        type="number"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Weight"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Pallets -->
                <b-col md="3">
                  <b-form-group
                    label="Pallets"
                    :label-for="`mc-operation_pallets${index}`"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Pallets"
                      rules="required"
                    >
                      <b-form-input
                        :id="`mc-operation_pallets${index}`"
                        v-model="operation.pallets"
                        type="number"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Pallets"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Cases -->
                <b-col md="3">
                  <b-form-group
                    label="Cases"
                    :label-for="`mc-operation_cases${index}`"
                  >
                    <b-form-input
                      :id="`mc-operation_cases${index}`"
                      v-model="operation.cases"
                      type="number"
                      placeholder="Cases"
                    />
                  </b-form-group>
                </b-col>

                <!-- Spots -->
                <b-col md="3">
                  <b-form-group
                    label="Spots"
                    :label-for="`mc-operation_spots${index}`"
                  >
                    <b-form-input
                      :id="`mc-operation_spots${index}`"
                      v-model="operation.spots"
                      type="number"
                      placeholder="Spots"
                    />
                  </b-form-group>
                </b-col>

                <!-- Temperature -->
                <b-col md="3">
                  <b-form-group
                    label="Temperature"
                    :label-for="`mc-operation_temperature${index}`"
                  >
                    <b-form-input
                      :id="`mc-operation_temperature${index}`"
                      v-model="operation.temperature"
                      type="number"
                      placeholder="Temperature"
                    />
                  </b-form-group>
                </b-col>

                <hr class="w-100">
              </b-row>
            </b-col>

            <b-button-group
              class="my-1 mx-1"
            >
              <b-button
                variant="outline-primary"
                @click="addOperation('pickup')"
              >
                Pickup +
              </b-button>
              <b-button
                variant="outline-primary"
                @click="addOperation('delivery')"
              >
                Delivery +
              </b-button>
            </b-button-group>

          </b-row>
        </validation-observer>
      </tab-content>
      
    </form-wizard>

    <b-modal
      id="confirm-modal"
      ok-title="Submit"
      centered
      title="Pre Planning"
      @ok="sumbit"
    >
      <div class="d-flex justify-content-between">

        <b-button
          pill
          :variant="load.actionType === 'straight_thru' ? 'flat-success' : 'flat-secondary'"
          @click="chooseActionType('straight_thru')"
        >
          <feather-icon
            icon="CheckIcon"
            size="18"
          />
          Straight Thru
        </b-button>

        <b-button
          pill
          :variant="load.actionType === 'p_d' ? 'flat-success' : 'flat-secondary'"
          @click="chooseActionType('p_d')"
        >
          <feather-icon
            icon="CheckIcon"
            size="18"
          />
          P / D
        </b-button>

        <b-button
          pill
          :variant="load.actionType === 'warehouse' ? 'flat-success' : 'flat-secondary'"
          @click="chooseActionType('warehouse')"
        >
          <feather-icon
            icon="CheckIcon"
            size="18"
          />
          Ware House
        </b-button>

      </div>
    </b-modal>
  </div>
</template>

<script>
// import { VueAutosuggest } from 'vue-autosuggest'
import { FormWizard, TabContent } from 'vue-form-wizard'
import {
  BRow, BCol, BFormGroup, BFormFile, BFormInput, BButton, BButtonGroup, BImg, BFormDatepicker, BFormTimepicker, BFormCheckbox, BModal, VBModal,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { required } from '@validations'
import vSelect from 'vue-select'
import * as loadService from '@/services/load'
import * as vendorService from '@/services/vendor'
import * as assetService from '@/services/asset'
import * as companyService from '@/services/company'
import * as userService from '@/services/user'
import states from '@/utils/state.json'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormFile,
    BFormInput,
    BButton,
    BButtonGroup,
    BImg,
    BFormDatepicker,
    BFormTimepicker,
    BFormCheckbox,
    BModal,
    // eslint-disable-next-line vue/no-unused-components
    VBModal,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    // VueAutosuggest,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      required,
      load: {
        rate_conformation: null,
        revived_rate_conformation: null,
        loadID: '',
        load_company: null,
        customer: null,
        equipment: null,
        customer_rep: null,
        customer_mc: '',
        customer_dot: '',
        operations: [],
        actionType: '',
        dispatcher: null,
        rate_confirmation_amount: '',
      },
      customers: [],
      customReps: [],
      equipments: [],
      locations: [],
      companies: [],
      dispatchers: [],
      filteredLocations: [],
      uploadThumbnail: require('@/assets/images/upload/fileupload.png'),
      preview: null,
      states,
      snowOption: {
        theme: 'snow',
      },
    }
  },
  created() {
    // eslint-disable-next-line no-underscore-dangle
    this.getCompany()
    this.getVendor()
    this.getAsset()
    this.getUsersByRole()
    if (this.$route.name === 'edit-load') {
      this.editId = this.$route.params.id
      this.getLoadById(this.$route.params.id)
    }
  },
  methods: {
    // final submit
    formComplete() {
      if (this.load.operations.filter(item => item.type === 'delivery').length > 0) {
        this.$bvModal.show('confirm-modal')
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Delivery type must be at least one.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    },

    // submit after choose actiontype of operation
    sumbit() {
      if (this.load.actionType) {
        this.$swal({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, submit it!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            if (this.$route.name === 'edit-load') {
              this.update()
            } else {
              this.create()
            }
          }
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Please choose action Type',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    },

    // create action
    create() {
      this.$store.commit('set_loading_action', true)
      
      // delete revived_rate_conformation
      delete this.load.revived_rate_conformation

      loadService.create({
        ...this.load,
        company_id: this.$store.state.auth.companyId,
      })
        .then(res => {
          this.$store.commit('set_loading_action', false)
          this.$swal({
            title: 'Success!',
            text: res.data.message,
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          }).then(() => {
            this.$router.push({ name: 'load-board' })
          })
        })
        .catch(err => {
          this.$store.commit('set_loading_action', false)
          this.$swal({
            title: 'Failed!',
            text: err.response.data.message,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
    },

    // update action
    update() {
      this.$store.commit('set_loading_action', true)
      // set params for update
      const data = {
        id: this.editId,
        acc_status: this.load.acc_status,
        operation_status: this.load.operation_status,
        rate_conformation: this.load.rate_conformation,
        revived_rate_conformation: this.load.revived_rate_conformation,
        bol: this.load.bol,
        pod: this.load.pod,
        freight_image: this.load.freight_image,
        misc: this.load.misc,
        loadID: this.load.loadID,
        customer: this.load.customer,
        load_company: this.load.load_company,
        equipment: this.load.equipment,
        customer_rep: this.load.customer_rep,
        customer_mc: this.load.customer_mc,
        customer_dot: this.load.customer_dot,
        rate_confirmation_amount: this.load.rate_confirmation_amount,
        actionType: this.load.actionType,
        operations: this.load.operations,
        dispatcher: this.load.dispatcher,
        company_id: this.$store.state.auth.companyId,
      }

      // delete if there is no update of rate confirmation
      if (data.rate_conformation === null || data.rate_conformation.length === 0) {
        delete data.rate_conformation
      }

      // delete if there is no update of revived_rate_conformation
      if (data.revived_rate_conformation === null || data.revived_rate_conformation.length === 0) {
        delete data.revived_rate_conformation
      }

      loadService.update(data)
        .then(res => {
          this.$store.commit('set_loading_action', false)
          this.$swal({
            title: 'Success!',
            text: res.data.message,
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          }).then(() => {
            this.$router.push({ name: 'load-board' })
          })
        })
        .catch(err => {
          this.$store.commit('set_loading_action', false)
          this.$swal({
            title: 'Failed!',
            text: err.response.data.message,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
    },

    // get Load detail by ID
    getLoadById(id) {
      this.$store.commit('set_loading_action', true)
      loadService.getLoadById(id)
        .then(async res => {
          this.$store.commit('set_loading_action', false)
          this.load = res
          this.preview = res.rate_conformation
          this.selectVendor(this.load.customer)
          this.load.rate_conformation = []
          // console.log(this.load)
        })
    },

    // rate confirmation document upload
    rateConUpload() {
      return new Promise((resolve, reject) => {
        this.$refs.rateconRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else if (this.$route.name === 'edit-load') {
            resolve(true)
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'You must upload rate confirmation document first!',
                icon: 'EditIcon',
                variant: 'danger',
              },
            })
            reject()
          }
        })
      })
    },

    // load details information submit
    loadDetail() {
      return new Promise((resolve, reject) => {
        this.$refs.loadDetail.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },

    // get customer lists
    async getVendor() {
      const res = await vendorService.getVendorShortDetails()
      this.customers = res.data.vendors
    },

    // get truck, trailer lists
    async getAsset() {
      this.equipments = await assetService.getAssetsByType({ type: 'Trailer' })
      // this.equipments = res.data.assets.map(asset => ({
      //   id: asset.id,
      //   name: `${asset.type}, ${asset.type === 'Trailer' ? `${asset.trailer_type}, ${asset.trailer_size}` : ''} ${asset.model}, ${asset.year}`,
      // }))
    },

    // get company
    async getCompany() {
      const res = await companyService.getCompanyByCompany()
      this.companies = res.data.companies
    },

    // get dispatcher
    async getUsersByRole() {
      this.dispatchers = await userService.getUsersByRole({ role: 'dispatcher' })
    },

    // view rate confirmation document
    viewRateCon() {
      if (this.$route.name === 'edit-load' && this.load.rate_conformation.length === 0) {
        const win = window.open('', '', 'left=0,top=0,width=1200,height=800,toolbar=0,scrollbars=0,status=0')
        win.document.write(
          `<body style="margin:0px;"><object data="${
            this.preview
          }" type="application/pdf" width="100%" height="100%"><iframe src="${
            this.preview
          }" scrolling="no" width="100%" height="100%" frameborder="0" ></iframe></object></body>`,
        )
      } else {
        const reader = new FileReader()
        reader.readAsDataURL(this.load.rate_conformation)
        let fileData = null
        // eslint-disable-next-line func-names
        reader.onload = function () {
          fileData = reader.result
          const win = window.open('', '', 'left=0,top=0,width=1200,height=800,toolbar=0,scrollbars=0,status=0')
          win.document.write(
            `<body style="margin:0px;"><object data="${
              fileData
            }" type="application/pdf" width="100%" height="100%"><iframe src="${
              fileData
            }" scrolling="no" width="100%" height="100%" frameborder="0" ></iframe></object></body>`,
          )
        }
      }
    },

    // add pickup and delivery information to load
    addOperation(value) {
      // total pickup foreight
      // eslint-disable-next-line camelcase
      const pk_weight = this.load.operations.length > 0 ? this.load.operations
        .filter(operation => operation.type === 'pickup')
        .map(item => (item.weight))
        .reduce((x, y) => Number(x) + Number(y), 0) : 0
      
      // eslint-disable-next-line camelcase
      const pk_pallets = this.load.operations.length > 0 ? this.load.operations
        .filter(operation => operation.type === 'pickup')
        .map(item => (item.pallets))
        .reduce((x, y) => Number(x) + Number(y), 0) : 0

      // eslint-disable-next-line camelcase
      const pk_cases = this.load.operations.length > 0 ? this.load.operations
        .filter(operation => operation.type === 'pickup')
        .map(item => (item.cases))
        .reduce((x, y) => Number(x) + Number(y), 0) : 0

      // eslint-disable-next-line camelcase
      const pk_spots = this.load.operations.length > 0 ? this.load.operations
        .filter(operation => operation.type === 'pickup')
        .map(item => (item.spots))
        .reduce((x, y) => Number(x) + Number(y), 0) : 0

      // total delivery foreight
      // eslint-disable-next-line camelcase
      const di_weight = this.load.operations.length > 0 ? this.load.operations
        .filter(operation => operation.type === 'delivery')
        .map(item => (item.weight))
        .reduce((x, y) => Number(x) + Number(y), 0) : 0

      // eslint-disable-next-line camelcase
      const di_pallets = this.load.operations.length > 0 ? this.load.operations
        .filter(operation => operation.type === 'delivery')
        .map(item => (item.pallets))
        .reduce((x, y) => Number(x) + Number(y), 0) : 0

      // eslint-disable-next-line camelcase
      const di_cases = this.load.operations.length > 0 ? this.load.operations
        .filter(operation => operation.type === 'delivery')
        .map(item => (item.cases))
        .reduce((x, y) => Number(x) + Number(y), 0) : 0

      // eslint-disable-next-line camelcase
      const di_spots = this.load.operations.length > 0 ? this.load.operations
        .filter(operation => operation.type === 'delivery')
        .map(item => (item.spots))
        .reduce((x, y) => Number(x) + Number(y), 0) : 0

      this.load.operations.push({
        type: value,
        location: '',
        city: '',
        zip: '',
        state: '',
        startDate: '',
        startTime: '',
        endDate: '',
        endTime: '',
        fcfs: false,
        appt: false,
        reference: '',
        Number: null,
        specialNote: '',
        seal: '',
        // eslint-disable-next-line camelcase
        weight: value === 'delivery' ? (pk_weight - di_weight) : 0,
        // eslint-disable-next-line camelcase
        commodity: value === 'delivery' ? this.load.operations[0].commodity : '',
        // eslint-disable-next-line camelcase
        pallets: value === 'delivery' ? (pk_pallets - di_pallets) : 0,
        // eslint-disable-next-line camelcase
        cases: value === 'delivery' ? (pk_cases - di_cases) : 0,
        // eslint-disable-next-line camelcase
        spots: value === 'delivery' ? (pk_spots - di_spots) : 0,
        temperature: null,
      })
    },

    // remove pickup or delivery information from load
    removeItem(index) {
      this.load.operations.splice(index, 1)
    },

    // notification after rate confirmation document
    fileupload(ev) {
      if (ev.target.value) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Rate Confirmation Document Uploaded!',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
      }
    },

    // event when select vendor
    selectVendor(e) {
      const filterVendor = this.customers.find(customer => customer.id === e)
      if (filterVendor) {
        this.customReps = filterVendor.representives
        this.load.customer_mc = filterVendor.operating_authority
        this.load.customer_dot = filterVendor.usdot
      } else {
        this.customReps = []
        this.load.customer_mc = ''
        this.load.customer_dot = ''
      }
    },
    
    // change value of operations appointment
    changeAppt(index, appt) {
      if (appt) {
        this.load.operations[index].endDate = this.load.operations[index].startDate
        this.load.operations[index].endTime = this.load.operations[index].startTime
      }
    },

    // choose action type
    chooseActionType(value) {
      this.load.actionType = value
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
